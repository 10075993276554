import React, { FC } from "react";
import { graphql, PageProps } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Img from "gatsby-image";
import {
  ContentfulPage,
  ContentfulStorlek,
  ContentfulAnnons,
} from "../../types/graphql-types";
import { Link as ScrollLink } from "react-scroll";

type Props = {
  contentfulPage: ContentfulPage & any;
};

const Rad = ({ alder, storlek }: ContentfulStorlek) => {
  return (
    <div className="content-textblock__table__row">
      <div className="content-textblock__table__row__left">{alder}</div>
      <div className="content-textblock__table__row__right">{storlek}</div>
    </div>
  );
};

const AnnonsAlt = ({ bild, produkt, sajt, lnk }: ContentfulAnnons) => {
  return (
    <a
      className="col-sm-12 col-md-6 col-lg-3 shopping__item"
      href={lnk as any}
      target="_blank"
    >
      <Img
        fluid={bild?.fluid as any}
        className="shopping__image"
        loading="eager"
      />
      <h3 className="shopping__name">{produkt}</h3>
      <div className="shopping__p">{sajt}</div>
      <div className="shopping__button">Köp här</div>
    </a>
  );
};

const Page: FC<PageProps<Props>> = ({
  data: {
    contentfulPage: {
      title = null,
      body,
      boxBody,
      storlekslista,
      annonsblock = null,
    },
  },
}) => {
  return (
    <Layout>
      <div>
        <SEO
          title={title ? title : ""}
          description={body.childMarkdownRemark.html.replace(
            /(<([^>]+)>)/gi,
            ""
          )}
        />
        <div className="sticky-nav">
          <div className="container">
            <div className="sticky-columns">
              <ScrollLink
                className="sticky-column"
                to="sizes"
                smooth={true}
                offset={-70}
                duration={400}
              >
                <span className="sticky-icon sticky-icon-size" />
                <span className="sticky-text">Visa tabellen</span>
              </ScrollLink>
              <ScrollLink
                className="sticky-column"
                to="shopping"
                smooth={true}
                offset={-70}
                duration={400}
              >
                <span className="sticky-icon sticky-icon-shopping" />
                <span className="sticky-text">Shoppingtips</span>
              </ScrollLink>
            </div>
          </div>
        </div>

        <main className="main info">
          <div className="container">
            <div className="content">
              <div className="content-textblock content-textblock--first">
                <h1 className="content-textblock__h">{title}</h1>
                <div
                  className="content-textblock__p"
                  dangerouslySetInnerHTML={{
                    __html: body.childMarkdownRemark.html,
                  }}
                />
                {boxBody && (
                  <div
                    className="content-textblock__box"
                    dangerouslySetInnerHTML={{
                      __html: boxBody.childMarkdownRemark.html,
                    }}
                  />
                )}
                <div id="sizes" />
              </div>
              <div className="content-textblock content-textblock--second">
                {storlekslista && (
                  <div>
                    <div className="content-textblock-header">
                      <h5>Ålder</h5>
                      <h5>Storlek</h5>
                    </div>
                    <div className="content-textblock__table">
                      {storlekslista.map((row: ContentfulStorlek) => (
                        <Rad {...row} key={row.id} />
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
            {annonsblock && (
              <div className="shopping" id="shopping">
                <h2 className="shopping__h">Shoppingtips</h2>
                <div className="row">
                  {annonsblock?.annonsblock?.map((block: ContentfulAnnons) => (
                    <AnnonsAlt {...block} key={block.id} />
                  ))}
                </div>
              </div>
            )}
          </div>
        </main>
      </div>
    </Layout>
  );
};

export default Page;

export const pageQuery = graphql`
  query pagerQuery($id: String!) {
    contentfulPage(id: { eq: $id }) {
      title
      slug
      id
      body {
        body
        childMarkdownRemark {
          html
        }
      }
      boxBody {
        childMarkdownRemark {
          id
          html
        }
      }
      storlekslista {
        id
        storlek
        alder
      }
      annonsblock {
        id
        annonsblock {
          id
          produkt
          sajt
          lnk
          bild {
            id
            fluid(quality: 100, maxWidth: 760) {
              ...GatsbyContentfulFluid
            }
            fixed(quality: 100, width: 760) {
              ...GatsbyContentfulFixed
            }
            resolutions(quality: 100) {
              base64
              aspectRatio
              width
              height
              src
              srcSet
            }
            description
          }
        }
      }
    }
    allContentfulPage {
      edges {
        node {
          id
          title
          slug
        }
      }
    }
    allContentfulMenu {
      edges {
        node {
          id
          pages {
            id
            title
            slug
          }
        }
      }
    }
    allContentfulAnnons {
      edges {
        node {
          id
          produkt
          sajt
          lnk
        }
      }
    }
  }
`;
